export const Database = [
  {
    title: "Epic User Manual",
    slug: "samsung-epic-user-manual",
    client: "Samsung",
    productionCompany: "Joint",
    director: "Scotti Raz",
    locations: "Amsterdam, Netherlands",
    role: "Line Producer & Post Producer",
    type: "Film",
    protected: false,
    description: "To bring Samsung into their audiences’ orbit, we needed to belong in their world. So for the latest Samsung Galaxy S Series, we made The Epic User Manual, a user manual made by users. For this campaign I’ve been the Line and Post Producer through W+K’s in-house production and post production company Joint (that I’ve started in Amsterdam).",
    headerImage: [
      {
        title: "Epic User Manual",
        url: require("../images/header/samsung-epic-user-manual.jpg"),
      }
    ],
    videosThreePart: [
      {
        title: "Epic User Manual - WiFi",
        url: require("../videos/samsung-eum-wifi.mp4"),
        index: "1",
        style: "case-threepart",
      },
      {
        title: "Epic User Manual - Yeti",
        url: require("../videos/samsung-eum-yeti.mp4"),
        index: "2",
        style: "case-threepart",
      },
      {
        title: "Epic User Manual - Giraffe Tower",
        url: require("../videos/samsung-eum-giraffe.mp4"),
        index: "3",
        style: "case-threepart case-nopadding",
      }
    ],
    copyFour: "We’ve directed some of these film shoots ourselves which I’ve produced as well as post produced those together with the manuals that were co-created with creators like Caleb Natale, Lea Sabban, Ben Treat, Izhan Ahmed, Bulut Sahin, Blake Stafford who are part of the Samsung Galaxy team.",
    videosThreePartTwo: [
      {
        title: "Epic User Manual - UFO",
        url: require("../videos/samsung-eum-ufo.mp4"),
        index: "4",
        style: "case-threepart",
      },
      {
        title: "Epic User Manual - QuickShare",
        url: require("../videos/samsung-eum-quickshare.mp4"),
        index: "5",
        style: "case-threepart",
      },
      {
        title: "Epic User Manual - Larping",
        url: require("../videos/samsung-eum-larping.mp4"),
        index: "6",
        style: "case-threepart case-nopadding",
      }
    ],
  },
  {
    title: "Like a Lioness",
    slug: "nike-like-a-lioness",
    client: "Nike",
    productionCompany: "Academy Films",
    director: "Sophia Ray",
    locations: "London, UK",
    role: "Senior Producer Lead",
    type: "Film",
    protected: false,
    description: "I’ve been brought in to cover part of this very fast turnaround production for W+K London. The Lionesses are a new generation of athletes that are showing us a more expansive definition of victory. They are driving the game, society & culture forward. Being a Lioness isn’t just about excelling in your sport: it’s about having a mentality of unreasonable ambition. It’s a mentality that we’ve called  ‘Like a Lioness’.",
    headerImage: [
      {
        title: "Nike - Like a Lioness",
        url: require("../images/header/nike-like-a-lioness.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Nike - Like a Lioness",
        url: require("../videos/nike-like-a-lioness.mp4"),
      }
    ],
    copyOne: "During the tournament, we were unmissable in her path. Dimensionalising the #LikeALioness mentality. Bringing football closer to Her & on Her terms. From London, to the World.",
    imageTwo: [
      {
        title: "Nike - Like a Lioness - Opening",
        url: require("../images/cases/nike-like-a-lioness-opening.gif"),
        style: "case-fourpart",
      },
      {
        title: "Nike - Like a Lioness - Lotte",
        url: require("../images/cases/nike-like-a-lioness-lotte.gif"),
        style: "case-fourpart",
      },
      {
        title: "Nike - Like a Lioness - Georgia",
        url: require("../images/cases/nike-like-a-lioness-georgia.gif"),
        style: "case-fourpart",
      },
      {
        title: "Nike - Like a Lioness - Lauren",
        url: require("../images/cases/nike-like-a-lioness-lauren.gif"),
        style: "case-fourpart case-nopadding",
      }
    ],
  },
  {
    title: "Galaxy Z Flip 5",
    slug: "samsung-z-flip-5",
    client: "Samsung",
    productionCompany: "Iconoclast",
    director: "Jenn Nkiru",
    locations: "Sofia, Bulgaria",
    role: "Senior Producer Lead",
    type: "Film",
    protected: true,
    description: "As part of the main campaign, directed by Jenn Nkiru, I’ve been brought in last minute to produce in a very tight deadline, this film featuring Jaden Smith who's a Galaxy Z Flip power user since it's inception. For this film I’ve produced the shoot portion as well as been the post producer (for W+K’s in-house post production studio Joint which I've started in Amsterdam). Both the editing and post production/finishing has been handled by me through Joint. ",
    headerImage: [
      {
        title: "Galaxy Z Flip 5",
        url: require("../images/header/samsung-z-flip-5.jpg"),
      }
    ],
    headerImageRevealed: [
      {
        title: "Galaxy Z Flip 5",
        url: require("../images/header/samsung-z-flip-5-revealed.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Galaxy Z Flip 5",
        url: require("../videos/samsung-z-flip-5-15s.mp4"),
      },
    ],
  },
  {
    title: "Can You Send Me That?",
    slug: "samsung-can-you-send-me-that",
    client: "Samsung",
    productionCompany: "Caviar",
    director: "Los Perez",
    locations: "Barcelona, Spain",
    role: "Senior Producer Lead",
    type: "Film",
    protected: false,
    description: "For Samsung’s latest flagship in the Galaxy S Series I’ve produced, together with a team of producers, 7 films to hero the people who make the most of the latest flagship smartphone. We highlighted how the different device features such as Nightography and Gaming Performance amongst others, generate social currency for the owners in their daily lives by making everyone else around them look on with envy and admiration.",
    headerImage: [
      {
        title: "Can You Send Me That?",
        url: require("../images/header/samsung-can-you-send-me-that.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Can You Send Me That? - Nightography",
        url: require("../videos/samsung-s-series-nightography.mp4"),
        index: "1",
      }
    ],
    copyOne: "Besides the main launch film we’ve also produced another one focusing on the champions of their gaming world. In this film we feature two gaming legends – a world-renowned gamer, Faker,  and a newly released racing game, which was previously only available on PCs and Consoles, but is now on mobile thanks to the power of the S23.",
    videoTwo: [
      {
        title: "Can You Send Me That? - Performance",
        url: require("../videos/samsung-s-series-performance.mp4"),
        index: "2",
      }
    ],
    copyThree: "We then also produced four product feature films that highlight the specific epic features that the latest powerhouse phone in the Galaxy S series has.",
    videoThree: [
      {
        title: "Can You Send Me That? - Vision Booster",
        url: require("../videos/samsung-s-series-vision-booster.mp4"),
        index: "3",
      },
      {
        title: "Can You Send Me That? - High Resolution",
        url: require("../videos/samsung-s-series-high-resolution.mp4"),
        index: "4",
      },
      {
        title: "Can You Send Me That? - Night Video",
        url: require("../videos/samsung-s-series-night-video.mp4"),
        index: "5",
      }
    ],
  },
  {
    title: "What Moves You Makes You",
    slug: "montblanc-wmymy",
    client: "Montblanc",
    productionCompany: "Anorak (Film) & WeFolk (Print)",
    director: "Ian Pons Jewell & Jonas Lindstroem",
    locations: "Barcelona, Spain",
    role: "Producer",
    type: "Film/Print",
    protected: false,
    description: "To celebrate the release of the Extreme 3.0 collection, I produced ‘On The Move’, a campaign that conveys the message that Montblanc products are made to accompany you on your journey, showing that it’s how we move, not where that defines the mark we make. For this campaign we've partnered with no one other than Cillian Murphy, who had partnered with the brand before.",
    headerImage: [
      {
        title: "What Moves You Makes You",
        url: require("../images/header/montblanc-what-moves-you-makes-you.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../videos/montblanc-what-moves-you-makes-you.mp4"),
        index: "1",
      }
    ],
    imageOne: [
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-cillian-in-reverse.gif"),
        style: "case-threepart",
      },
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-cillian-walking.gif"),
        style: "case-threepart",
      },
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-coin-in-reverse.gif"),
        style: "case-threepart case-nopadding",
      },
    ],
    copyTwo: "Besides the film campaign, that needed some proper shoot planning as we were shooting the scenes twice as we needed reverse footage as well, I've also produced the print campaign which both were shot back to back.",
    imageTwo: [
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-what-moves-you-makes-you-1.jpg"),
        style: "case-threepart",
      },
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-what-moves-you-makes-you-2.jpg"),
        style: "case-threepart",
      },
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-what-moves-you-makes-you-3.jpg"),
        style: "case-threepart case-nopadding",
      }
    ],
    imageThree: [
      {
        title: "Montblanc - What Moves You, Makes You",
        url: require("../images/cases/montblanc-what-moves-you-makes-you-print.jpg"),
      }
    ]
  },
  {
    title: "Land of New Football",
    slug: "nike-land-of-new-football",
    client: "Nike",
    productionCompany: "Stink",
    director: "Felix Brady",
    locations: "UK, France & Portugal",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "In the midst of one of the biggest football tournaments taking place in summer 2021, we launched “The land of New Football” – a campaign I produced that celebrates and highlights the power of everyday footballers and their way of pushing football forward by playing on their own terms. It articulates a new vision for global football. And that future can’t be pinned down to just one thing. The sport’s future is full of talent, stacked with players who bring incredible skills to the game. It’s inclusive, open to all backgrounds in support of young players finding joy in the sport, and it’s aware of the power of collective work against systemic obstacles.",
    headerImage: [
      {
        title: "Land of New Football",
        url: require("../images/header/nike-land-of-new-football.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Land of New Football",
        url: require("../videos/nike-land-of-new-football.mp4"),
        index: "1",
      }
    ],
    copyTwo: "In the 90-second hero film, we imagined all the football pitches in the world stitched to one another forming a gigantic piece of land, from where the new generation of players declare their independence from the old ways of the game. The film features a wide variety of athletes, from professional footballers like Marcus Rashford, Sara Däbritz and Richarlison, to JJ Roble, the UK’s first Muslim referree, and local football communities around Europe like Alesia FC in France or Football Beyond Borders in the UK.",
    videosThreePart: [
      {
        title: "Land of New Football - New Football News - Episode 1",
        url: require("../videos/nike-land-of-new-football-new-football-news-1.mp4"),
        index: "2",
        style: "case-threepart",
      },
      {
        title: "Land of New Football - New Football News - Episode 2",
        url: require("../videos/nike-land-of-new-football-new-football-news-2.mp4"),
        index: "3",
        style: "case-threepart",
      },
      {
        title: "Land of New Football - New Football News - Episode 3",
        url: require("../videos/nike-land-of-new-football-new-football-news-3.mp4"),
        index: "4",
        style: "case-threepart case-nopadding",
      },
    ],
    copyFour: "Further to the film, we produced our own weekly news show “New Football News”, where we reacted to the best football content inside and outside of the tournament throughout the campaign. Welcome to the Land of New Football. It’s everyone’s to build and play – no matter who you are, where you’re from, or how long you’ve been in the game.",
  },
  {
    title: "Awesome is for Everyone Vol. 3",
    slug: "samsung-awesome-is-for-everyone-2021",
    client: "Samsung",
    productionCompany: "Stink",
    director: "Felix Brady",
    locations: "Thailand, Nigeria, Brazil, USA, UK, Netherlands & Ukraine",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "In Vol 1. we all learned that the Galaxy A has an awesome screen, an awesome camera, and long-lasting battery life. In Vol 2. we took a step further by giving everyone first-hand experience of how the awesome features of the Galaxy A actually work. This time in Vol 3. the campaign is a celebration of all of the awesome things people do when they have access to great smartphone technology. I’ve produced the launch films of the new A series device in which we bring together creatives from all around the world to collaborate and build an exciting and dynamic mosaic inspired by the Duet chains and culture of co-creation on social platforms. ",
    headerImage: [
      {
        title: "Awesome is for Everyone Vol. 3",
        url: require("../images/header/samsung-awesome-is-for-everyone-2021.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Awesome is for Everyone Vol. 3",
        url: require("../videos/samsung-aife-2021.mp4"),
        index: "1",
      },
    ],
    copyTwo: "They start with one piece of footage and encourage other creators to join and add something to the story, forming an endless mosaic that builds a strong sense of connection with others around the world – the new phenomenon of global culture sync in action. The film takes the viewer from a Bangkok tattoo artist Parangew, to Jonathan Neguebites dancing on a beach in Rio, to a twins rap duo Ain’t Afraid in Michigan, to the first Nigeria’s skate crew Wafflesncream, to Vogue choreographer Alina Ryzhkova in snowy Kiev, to Kurdish-British model and activist Deba, and so on. The ride is accompanied by a remix of the iconic techno banger that highlights the awesome benefits of Galaxy A: “Awesome screen, awesome camera, long lasting battery life”.",
    videosThreePart: [
      {
        title: "Awesome is for Everyone Vol. 3 - Security",
        url: require("../videos/samsung-aife-2021-security.mp4"),
        index: "2",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Display & Performance",
        url: require("../videos/samsung-aife-2021-display-performance.mp4"),
        index: "3",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Camera & Water Resistance",
        url: require("../videos/samsung-aife-2021-camera-water-resistance.mp4"),
        index: "4",
        style: "case-threepart case-nopadding",
      },
    ],
    copyFour: "The campaign was produced during the pandemic and was a major production as it was captured in every continent with shoots in Thailand, Nigeria, Brazil, USA, UK, Netherlands and Ukraine and played out via a range of assets across TV, digital and social. This required a lot of planning from our director and editor to ensure what we were capturing across these countries remotely was going to work out in the edit and post.",
    imageFour: [
      {
        title: "Awesome is for Everyone Vol. 3 - Boards",
        url: require("../images/cases/samsung-aife-2021-board-1.webp"),
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Boards",
        url: require("../images/cases/samsung-aife-2021-board-2.webp"),
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Boards",
        url: require("../images/cases/samsung-aife-2021-board-3.webp"),
        style: "case-threepart case-nopadding",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Edit",
        url: require("../images/cases/samsung-aife-2021-edit.webp"),
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Remote",
        url: require("../images/cases/samsung-aife-2021-remote-1.webp"),
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Remote",
        url: require("../images/cases/samsung-aife-2021-remote-2.webp"),
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Remote",
        url: require("../images/cases/samsung-aife-2021-remote-3.webp"),
        style: "case-threepart case-nopadding",
      },
      {
        title: "Awesome is for Everyone Vol. 3 - Monitor",
        url: require("../images/cases/samsung-aife-2021-monitor.webp"),
      }
    ],
  },
  {
    title: "Awesome is for Everyone Vol. 2",
    slug: "samsung-awesome-is-for-everyone-2020",
    client: "Samsung",
    productionCompany: "Somesuch",
    director: "Raine Allen Miller",
    locations: "London, UK",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "In Awesome is for Everyone Vol. 1 we all had learned that the Galaxy A phone has an awesome screen, an awesome camera, and long-lasting battery life. This time, Samsung wanted to go one step further and give everyone a first-hand experience of how these awesome features actually work. I’ve produced this campaign in which we make viewers feel as if their own phone has been taken over and they witness a fast-paced trip through the phone, fuelled by footage from content creators all around the world. Our music track that went viral came back in a new flavour for this campaign as well. The UI of the Samsung phone was one of the key elements that had to be perfect to make sure people would feel that the film would feel like a real take over of their phones.",
    headerImage: [
      {
        title: "Awesome is for Everyone Vol. 2",
        url: require("../images/header/samsung-awesome-is-for-everyone-2020.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Awesome is for Everyone Vol. 2",
        url: require("../videos/samsung-aife-2020.mp4"),
        index: "1",
      },
    ],
    copyTwo: "The hero film is a rollercoaster ride through different phone features and social media platforms that takes us from paparazzi aliens to surfing cats, from game characters playing baseball with meteorites to an ad within an ad.",
    videosTwoPart: [
      {
        title: "Awesome is for Everyone Vol. 2 - Screen",
        url: require("../videos/samsung-aife-2020-screen.mp4"),
        poster: require("../images/cases/samsung-aife-2020-screen-poster.jpg"),
        index: "2",
        style: "case-twopart",
      },
      {
        title: "Awesome is for Everyone Vol. 2 - Storage",
        url: require("../videos/samsung-aife-2020-storage.mp4"),
        index: "3",
        style: "case-twopart case-nopadding",
      },
    ],
    copyThree: "Additionally to the hero film, we’ve made seven 15-second product feature films that highlight the phones main features (screen, camera, battery, toughness, storage, security, performance power) that further highlight why this phone is so awesome.",
    videosThreePart: [
      {
        title: "Awesome is for Everyone Vol. 2 - Battery",
        url: require("../videos/samsung-aife-2020-battery.mp4"),
        poster: require("../images/cases/samsung-aife-2020-battery-poster.jpg"),
        index: "4",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 2 - Performance",
        url: require("../videos/samsung-aife-2020-performance.mp4"),
        index: "5",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 2 - Camera",
        url: require("../videos/samsung-aife-2020-camera.mp4"),
        index: "6",
        style: "case-threepart case-nopadding",
      },
    ],
    copyFour: "This production was the first one when the COVID-19 pandemic happened which meant a lot of restrictions for production. All footage was shot remotely and on Galaxy A phones. It was important to have an even more planned and technical approach to the animations and investigate what was possible to create on the device itself, as this had to be incorporated with the footage captured during the remote shoot.",
  },
  {
    title: "Awesome is for Everyone Vol. 1",
    slug: "samsung-awesome-is-for-everyone-2019",
    client: "Samsung",
    productionCompany: "Riff Raff",
    director: "Ryan Staake",
    locations: "Bangkok, Thailand",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "For Samsung I’ve produced the global campaign ‘AWESOME is for Everyone’ to introduce the new A Series phone to the world. To position the new A Series phone as the go-to device for the Gen-Z creators we’ve brought in content creators, including Madrona Redhawk, Yvng Homie, DJ Rangster to feature in the campaign. We’ve are highlighting the key features of the product in song form which is that the phone has an awesome screen, awesome camera and a looooooooooooooong lasting battery life.",
    headerImage: [
      {
        title: "Awesome is for Everyone Vol. 1",
        url: require("../images/header/samsung-awesome-is-for-everyone-2019.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Awesome is for Everyone Vol. 1",
        url: require("../videos/samsung-aife-2019.mp4"),
        index: "1",
      },
    ],
    copyOne: "This was the first piece of work for Samsung coming out of the W+K Amsterdam office and it was all about the age of live video. Particularly the way in which it is used on social media platforms like Instagram and TikTok.",
    videosThreePart: [
      {
        title: "Awesome is for Everyone Vol. 1 - Long Lasting Battery Life",
        url: require("../videos/samsung-aife-2019-battery.mp4"),
        index: "2",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 1 - Performance",
        url: require("../videos/samsung-aife-2019-performance.mp4"),
        index: "3",
        style: "case-threepart",
      },
      {
        title: "Awesome is for Everyone Vol. 1 - Fast Charging",
        url: require("../videos/samsung-aife-2019-fastcharging.mp4"),
        index: "4",
        style: "case-threepart case-nopadding",
      },
    ],
    copyFour: "To ensure no Gen-Z eyeball is spared we made six 15-second feature ads and over 300 social formats to accompany the hero film. We've worked with content creators all over the world to help these films come to live. Even the music was made by 'bedroom producers' and up and coming musicians.",
  },
  {
    title: "What Will They Say About You",
    slug: "nike-what-will-they-say-about-you",
    client: "Nike",
    productionCompany: "Division",
    director: "Fleur Fortune",
    locations: "Dubai, UAE",
    role: "Associate Producer",
    type: "Film",
    protected: false,
    description: "What will they say about you?” is a question many young Arab females are challenged with at home. Women are met with this phrase from family and friends when they endeavor to try something unexpected or try to push boundaries beyond social norms. We producer a film highlighting five remarkable women who have achieved personal success through competitive and amateur sport.  Despite concerns or criticism, these women hope that the world will say they’re pioneers, role models and strong voices for their region.",
    headerImage: [
      {
        title: "Nike - What Will They Say About You",
        url: require("../images/header/nike-what-will-they-say-about-you.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Nike - What Will They Say About You",
        url: require("../videos/nike-what-will-they-say-about-you.mp4"),
        index: "1",
      },
    ],
    copyOne: "The film features both professional and everyday athletes from the Arab region, including Parkour Trainer, Amal Mourad; Figure Skater, Zahra Lari; Pop Singer; Balquees Fathi; Fencer, Inès Boubakri; and Boxer, Arifa Bseiso.  Narration is done by Fatima Al-Banawi, a Saudi Arabian social researcher, artist, and actress.",    
  },
  {
    title: "The Ball Makes Us More",
    slug: "nike-the-ball-makes-us-more",
    client: "Nike",
    productionCompany: "RESET Content",
    director: "Wally Pfister",
    locations: "Barcelona, Spain",
    role: "Associate Producer",
    type: "Film",
    protected: false,
    description: "To celebrate the special relationship between FC Barcelona — the beating heart of the city and a Nike partner since ’98 — and its supporters, we created ‘The Ball Makes Us More’. Countless Catalans helped us make the 90-second film; some appear on camera while others worked behind the lens.",
    headerImage: [
      {
        title: "Nike - The Ball Makes Us More",
        url: require("../images/header/nike-the-ball-makes-us-more.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Nike - The Ball Makes Us More",
        url: require("../videos/nike-the-ball-makes-us-more.mp4"),
        index: "1",
      },
    ],
    copyOne: "Directed by Oscar-winning cinematographer Wally Pfister, ‘The Ball Makes Us More’ features some of the biggest names in FC Barcelona history, including Iniesta, Coutinho and Martens, along with 20 extras who are all real supporters from the city. The grid visual style used to bring the film to life was inspired by the layout of the city, a system that was designed and built in the mid-19th century by urban planner Ildefons Cerdà and remains unique to Barcelona.",
  },
  {
    title: "Never Ask",
    slug: "nike-never-ask",
    client: "Nike",
    productionCompany: "Riff Raff",
    director: "Finn Keenan",
    locations: "Tbilisi, Georgia & Moscow, Russia",
    role: "Producer",
    type: "Film/Social",
    protected: false,
    description: "Ahead of summer’s biggest football tournament in 2018, we unveiled ‘Never Ask’ – a campaign that highlights the unbreakable determination of Russian athletes. Through ‘Never Ask’, Nike aimed to motivate Russia’s young generation to push for their human potential, follow their dreams and embrace their own pioneering mindset. Taking inspiration from both professional and everyday athletes, we used the power of sport to help push boundaries and redefine what it means to do sports in Russia.",
    headerImage: [
      {
        title: "image title",
        url: require("../images/header/nike-never-ask.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Nike - Never Ask - Khoreva",
        url: require("../videos/nike-never-ask-khoreva.mp4"),
        index: "1",
      },
    ],
    videosTwoPart: [
      {
        title: "Nike - Never Ask - Smolov",
        url: require("../videos/nike-never-ask-smolov.mp4"),
        index: "2",
        style: "case-twopart",
      },
      {
        title: "Nike - Never Ask - Medvedeva",
        url: require("../videos/nike-never-ask-medvedeva.mp4"),
        index: "3",
        style: "case-twopart case-nopadding",
      },
    ],
    videosThreePart: [
      {
        title: "Nike - Never Ask - Shengeliya",
        url: require("../videos/nike-never-ask-shengeliya.mp4"),
        index: "4",
        style: "case-threepart",
      },
      {
        title: "Nike - Never Ask - Zobnin",
        url: require("../videos/nike-never-ask-zobnin.mp4"),
        index: "5",
        style: "case-threepart",
      },
      {
        title: "Nike - Never Ask - Koblova",
        url: require("../videos/nike-never-ask-koblova.mp4"),
        index: "6",
        style: "case-threepart case-nopadding",
      },
    ]
  },
  {
    title: "Independance",
    slug: "msf-independance",
    client: "Médecins Sans Frontières",
    productionCompany: "Division",
    director: "Helmi",
    locations: "Kiev, Ukraine",
    role: "Producer",
    type: "Film/Print",
    protected: false,
    description: "Independence is priceless but it has a cost. To help support MSF's independence, we created an online crowdfunding platform, aimed at young French donors and seeking to raise €1mil euros in 60 days. As part of that we've developed the website as well as the main film leading to the site.",
    headerImage: [
      {
        title: "image title",
        url: require("../images/header/msf-independance.jpg"),
      }
    ],
    videoOne: [
      {
        title: "MSF - Independance",
        url: require("../videos/msf-independance.mp4"),
        index: "1",
      },
    ],
    copyOne: "MSF had partnered with French actor Ahmed Dramé, who served as an ambassador for the platform and stars in film at the heart of the campaign to promote it. The 90-second film is featured on the platform itself, while a 30-second version had aired on television and online. The campaign is additionally supported by print, guerrilla posters and digital and social executions. ",
    imageTwo: [
      {
        title: "MSF - Independance - Website",
        url: require("../images/cases/msf-independance-website.gif"),
      }
    ],
    copyThree: "The platform offered 20 unexpected and exclusive rewards fit for any donation. Reflecting MSF’s spirit, each reward partner is an independent brand, designer or personality, who had donated their time and created limited edition products or experiences specifically for the crowd-funding platform. In addition to apparel such as clothing and shoes, experience rewards like playing Operation with an MSF doctor or witnessing the action first hand on a real field mission in conjunction with VICE News, gave everyone the opportunity to be part of MSF’s mission.",
    videosFourPart: [
      {
        title: "MSF - Independance - Cat",
        url: require("../videos/msf-independance-cat.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "2",
      },
      {
        title: "MSF - Independance - Training",
        url: require("../videos/msf-independance-training.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "3",
      },
      {
        title: "MSF - Independance - Sweater",
        url: require("../videos/msf-independance-sweater.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "4",
      },
      {
        title: "MSF - Independance - Pins",
        url: require("../videos/msf-independance-pins.mp4"),
        style: "case-fourpart case-nopadding",
        autoplay: true,
        muted: true,
        index: "5",
      },
    ],
  },
  {
    title: "Stories Are Everywhere",
    slug: "instagram-stories-are-everywhere",
    client: "Instagram",
    productionCompany: "CANADA",
    director: "MANSON",
    locations: "Barcelona, Spain",
    role: "Producer",
    type: "DOOH",
    protected: false,
    description: "Stories Are Everywhere was a multi-channel, integrated campaign for Instagram that presented the world through the lens of Instagram Stories. Inspired by the endless ways that the Instagram community was already using Stories tools, the campaign demonstrated the infinite ways that stickers, Boomerang, brushes and the Live function can help turn any moment into something special — it showed that “Stories Are Everywhere.",
    headerImage: [
      {
        title: "Instagram - Stories Are Everywhere",
        url: require("../images/header/instagram-stories-are-everywhere.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Instagram - Stories Are Everywhere - Basketball",
        url: require("../videos/instagram-stories-basketball.mp4"),
        autoplay: true,
        muted: true,
        index: "1",
      },
      {
        title: "Instagram - Stories Are Everywhere - Skater",
        url: require("../videos/instagram-stories-skater.mp4"),
        autoplay: true,
        muted: true,
        index: "2",
      },
      {
        title: "Instagram - Stories Are Everywhere - Pool",
        url: require("../videos/instagram-stories-pool.mp4"),
        autoplay: true,
        muted: true,
        index: "3",
      },
    ],
    copyThree: "This work was part of the full campaign which was shot by MANSON in Barcelona. The DOOH was part of the OOH/Print and Social campaign and you see here a selection of the work we've made which was seen in placements all over the US, Italy and Germany. The production of these DOOH had to match the exact use of Instagram Stories, from the way the type functions to all the drawings. This required some technical exploration, espcecially as it had to be adaptable for other markets. ",
    videosThreePart: [
      {
        title: "Instagram - Stories Are Everywhere - Dancer",
        url: require("../videos/instagram-stories-dancer.mp4"),   
        style: "case-threepart",
        autoplay: true,
        muted: true,
        index: "4",
      },
      {
        title: "Instagram - Stories Are Everywhere - Sea",
        url: require("../videos/instagram-stories-sea.mp4"),
        style: "case-threepart",
        autoplay: true,
        muted: true,
        index: "5",
      },
      {
        title: "Instagram - Stories Are Everywhere - Bee",
        url: require("../videos/instagram-stories-bee.mp4"),
        style: "case-threepart case-nopadding",
        autoplay: true,
        muted: true,
        index: "6",
      },
    ],
  },
  {
    title: "Lost in Space",
    slug: "instagram-lost-in-space",
    client: "Instagram",
    productionCompany: "CANADA",
    director: "MANSON",
    locations: "Barcelona, Spain",
    role: "Producer",
    type: "Film",
    protected: true,
    description: "Instagram asked us to make a film to launch the tools that help teens manage their time on Instagram, for a more mindful and positive experience. The project was finished and delivered but was never released (due to reasons unrelated to the work).",
    headerImage: [
      {
        title: "Instagram - Lost in Space",
        url: require("../images/header/instagram-lost-in-space.jpg"),
      }
    ],
    headerImageRevealed: [
      {
        title: "Instagram - Lost in Space",
        url: require("../images/header/instagram-lost-in-space-revealed.jpg"),
      }
    ],
    videoOne: [
      {
        url: require("../videos/instagram-lost-in-space-60s.mp4"),
        index: "1",
      }
    ],
    copyOne: "In the film, we see a girl daydreaming and getting lost in space. Surrounded by all the things she likes she's floating through her own world, losing track of time.",
    videosThreePart: [
      {
        title: "Instagram - Lost in Space - Part 1",
        url: require("../videos/instagram-lost-in-space-part-1.mp4"),
        style: "case-threepart",
        autoplay: true,
        muted: true,
        index: "3",
      },
      {
        title: "Instagram - Lost in Space - Part 2",
        url: require("../videos/instagram-lost-in-space-part-2.mp4"),
        style: "case-threepart",
        autoplay: true,
        muted: true,
        index: "4",
      },
      {
        title: "Instagram - Lost in Space - Part 3",
        url: require("../videos/instagram-lost-in-space-part-3.mp4"),
        style: "case-threepart case-nopadding",
        autoplay: true,
        muted: true,
        index: "4",
      },
    ],
    copyFour: "We've had fun recording all floating elements in real life with our director MANSON against greenscreen instead of going the CG/Digital route.",
    videosFourPart: [
      {
        title: "Instagram - Lost in Space",
        url: require("../videos/instagram-lost-in-space-manson-canada-wieden-kennedy3.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "8",
      },
      {
        title: "Instagram - Lost in Space",
        url: require("../videos/instagram-lost-in-space-manson-canada-wieden-kennedy4.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "9",
      },
      {
        title: "Instagram - Lost in Space",
        url: require("../videos/instagram-lost-in-space-manson-canada-wieden-kennedy5.mp4"),
        style: "case-fourpart",
        autoplay: true,
        muted: true,
        index: "10",
      },
      {
        title: "Instagram - Lost in Space",
        url: require("../videos/instagram-lost-in-space-manson-canada-wieden-kennedy6.mp4"),
        style: "case-fourpart case-nopadding",
        autoplay: true,
        muted: true,
        index: "11",
      },
    ],
  },
  {
    title: "E1000",
    slug: "epic-e1000",
    client: "Epic Aircraft",
    productionCompany: "Stink (film) & Kaliber (website)",
    director: "Pleix",
    locations: "",
    role: "Producer",
    type: "Film/Website",
    protected: false,
    description: "Epic Aircraft is an aircraft manufacturer with a focus on performance. To celebrate the certification and launch of their first full production aircraft, the E1000, the fastest and highest performing aircraft in its category, we rebuilt the entire Epic brand and as part of that we have created a certification product film directed by Pleix",
    headerImage: [
      {
        title: "image title",
        url: require("../images/header/epic-e1000.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Epic - E1000 Certification Product Film",
        url: require("../videos/epic-e1000.mp4"),
        poster: require("../images/cases/epic-e1000_poster.jpg")
      },
    ],
    copyOne: "As part of the rebranding we've also developed a website from which the development was done by Kaliber. The website features all details regardig the new E1000 as well as additional information from Epic Aircraft",
    videoTwo: [
      {
        title: "Epic - E1000 Website",
        url: require("../videos/epic-e1000-website.mp4"),
      }
    ],
  },
  {
    title: "Hello Future",
    slug: "orsted-hello-future",
    client: "Ørsted",
    productionCompany: "Knucklehead",
    director: "Ben Gregor",
    locations: "London, UK",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "Ørsted, a Danish green energy company, wanted to encourage parents and children to talk about climate change and understand the importance of green energy. To help them do that weve created a children's book and this film.",
    headerImage: [
      {
        title: "Ørsted - Hello Future",
        url: require("../images/header/orsted-hello-future.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Ørsted - Hello Future",
        url: require("../videos/orsted-hello-future.mp4"),
      }
    ],
    copyOne: "The 70 second film features a cast of nine kids aged 7-8 years old on a green screen set, dancing and waving along to the “Hello Future” song as they say bye bye to the energy of the past and hello to a greener future."
  },
  {
    title: "GO NOORD",
    slug: "noord-go-noord",
    client: "Skatepark NOORD",
    productionCompany: "Cake Film",
    director: "John Filipe",
    locations: "Amsterdam, Netherlands",
    role: "Producer",
    type: "Film",
    protected: false,
    description: "In 2014 Amsterdam’s only indoor skate park closed. Leaving the city’s large skateboarding community to face Amsterdam’s unpredictable weather, until the passion of a few created NOORD – a new indoor park that opened in 2017. NOORD, meaning North in Dutch, is situated in the North of Amsterdam. Building up to the park’s opening, we created ‘GO NOORD’, an online film designed to create heat around the launch.",
    headerImage: [
      {
        title: "Go Noord!",
        url: require("../images/header/noord-go-noord.jpg"),
      }
    ],
    videoOne: [
      {
        title: "Go Noord!",
        url: require("../videos/noord-go-noord.mp4"),
      }
    ],
    copyOne: "We've depicted a journey from the south of Amsterdam to the new park in the North from a skateboarder’s point of view. The film worked to let people know where the fun is at, where skateboarders can find a home and safe space that allows them to be active and meet like-minded people. Throughout the film, you’ll see some of the city’s iconic outdoor skate spots.",
  }
];
