import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import aboutPhoto from '../images/profile_square.png';

import '../stylesheets/App.css';
import '../stylesheets/About.css';

const About = () => {
  return (
    <div className="about">
      <motion.div initial="initial" animate="animate" exit="exit" className="aboutContainer">
        <div className='aboutTitle'>
          <span className='aboutAmazigh'>ⵅⴰⵍⴷ ⵉⵍ ⵅⵡⴰⵏⵉ</span>
          <span className='aboutArabic'>خالد الخواني</span>
        </div>
        <span className='aboutDescription'>
          <p>Hey there, I'm Khalid El Khouani, a 32-year old bloke born & bred in the Netherlands while embracing the colourful tapestry of Moroccan Amazigh heritage. I am an experienced Producer who created work that has been featured in various media for clients such as Nike, Samsung, Instagram, Facebook, Montblanc, Electronic Arts and more.</p>
          <p>Having been working in the business for 10+ years as a filmmaker, designer, developer, animator, vfx artist and producer, has led to accumulating in-depth knowledge on how the work is made and gives me the edge in producing quality work despite time and budgetary challenges.</p>
          <p>I've started W+K Amsterdam's in-house production and post production studio Joint and on the side enjoy exploring and learning about the new world of tech (AI/Unreal Engine/Web3/Virtual Production) and see where that leads.</p>
          <p>I feel strongly about sharing knowledge and helping new talent grow, especially from underrepresented communities, if you would like to get in touch for an internship, mentoring and/or shadowing opportunities or having one-to-one/group mentoring session, feel free to reach out.</p>
          <p>Currently working as a Senior Producer Lead at Wieden+Kennedy Amsterdam.</p>
        </span>
        {/* <img className='aboutPhoto' alt="" src={aboutPhoto} /> */}
      </motion.div>
    </div>
  );
}

export default About;



