import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Nav from './components/Nav';
import AnimatedRoutes from './components/AnimatedRoutes';

import './stylesheets/App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Nav />
        <AnimatedRoutes />
      </Router>
    </div>

  );
}

export default App;